import { createSlice } from '@reduxjs/toolkit';
import {
    googleLoginForInstructor,
    loginInstructor,
    otpRegisterByMailInstructor,
    registerInstructor,
    submitOtpForRegistrationInstructor,
    verifyInstructorCaptcha
} from '../../../../API/InstructorAuth';

//initial state for instructor auth
const initialState = {
    loading: false,
    userInfo: null,
    userToken: null,
    error: null,
    instructorSuccess: false,
    alreadyExists: null,
    isGoogleRegister: null,
    googleProfile: {},
    userProfile: {},
    isInstructor: null,
    captchaSuccessInstructor: false,
    registrationOtpVerifiedInstructor: false,
    registrationOtpSentInstructor: false,
    isInstructorOnboarded: false
};

const InstructorAuthSlice = createSlice({
    name: 'InstructorAuthSlice',
    initialState,
    reducers: {
        //instructor logout reducer
        logoutInstructor: (state) => {
            state.loading = false;
            state.userInfo = null;
            state.userToken = null;
            state.error = null;
            state.instructorSuccess = false;
            state.isInstructor = null;
            state.captchaSuccessInstructor = false;
            state.registrationOtpSentInstructor = false;
            state.registrationOtpVerifiedInstructor = false;
            state.alreadyExists = null;
            state.isInstructorOnboarded = false;
        },
        toggleForgotPassword: (state) => {
            //forgot password toggle
            state.forgotPassword = !state.forgotPassword;
        },
        setGoogleProfile: (state, { payload }) => {
            //set google profile
            state.googleProfile = payload;
        },
        setInstructorUserProfile: (state, { payload }) => {
            //set instructor profile
            state.userProfile = payload;
        },
        setInstructorSuccess: (state, { payload }) => {
            //set instructor success toggle state as false
            state.instructorSuccess = false;
        },
        setInstructorSuccessTrue: (state, { payload }) => {
            //set instructor success toggle state as true
            state.instructorSuccess = true;
        },
        setUserToken: (state, { payload }) => {
            //set instructor token
            state.userToken = payload;
        },
        setRegistrationOtpSentInstructor: (state, { payload }) => {
            //set instructor otp for registration toggle state
            state.registrationOtpSentInstructor = payload;
        },
        setIsInstructorOnboarded: (state, { payload }) => {
            // set if instructor is onboarded or not
            state.isInstructorOnboarded = true;
        }
    },
    extraReducers: {
        //captcha verification reducer
        [verifyInstructorCaptcha.pending]: (state) => {
            (state.loading = true),
                (state.error = null),
                (state.instructorSuccess = false);
        },
        [verifyInstructorCaptcha.fulfilled]: (state, { payload }) => {
            (state.loading = false),
                // (state.instructorSuccess = true),
                (state.error = null);
            state.captchaSuccessInstructor = payload?.data?.data?.success;
        },
        [verifyInstructorCaptcha.rejected]: (state, { payload }) => {
            (state.loading = false),
                (state.error = payload),
                (state.instructorSuccess = false);
        },

        //manual login reducer
        [loginInstructor.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.instructorSuccess = false;
        },
        [loginInstructor.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.userInfo = payload.data;
            state.userToken = payload.token;
            state.alreadyExists = payload.status;
            state.instructorSuccess = payload.status;
            state.captchaSuccessInstructor = false;
            state.isInstructorOnboarded = true;
            // state.isInstructor = payload?.data?.isInstructor;
        },
        [loginInstructor.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.instructorSuccess = false;
        },

        //google oauth reducers
        [googleLoginForInstructor.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.instructorSuccess = false;
            state.alreadyExists = null;
        },
        [googleLoginForInstructor.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.userInfo = payload.user;
            state.userToken = payload.token;
            state.alreadyExists = payload.alreadyExist;
            state.isGoogleRegister = true;
            state.isInstructor = payload.user.isInstructor;
            state.instructorSuccess = payload.status;
            // setting success state according to existing user or not
            if (payload.alreadyExist) {
                state.isInstructorOnboarded = true;
            } else {
                state.isInstructorOnboarded = false;
            }
        },
        [googleLoginForInstructor.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.instructorSuccess = false;
            state.alreadyExists = null;
        },

        // manual register reducer
        [registerInstructor.pending]: (state) => {
            (state.loading = true),
                (state.error = null),
                (state.instructorSuccess = false);
        },
        [registerInstructor.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.userInfo = payload.data;
            state.userToken = payload.token;
            state.alreadyExists = false;
            state.isGoogleRegister = false;
            state.instructorSuccess = payload.status;
            state.isInstructorOnboarded = false;
            // state.isInstructor = payload.data.isInstructor;
        },
        [registerInstructor.rejected]: (state, { payload }) => {
            (state.loading = false),
                (state.error = payload),
                (state.instructorSuccess = false);
        },

        //otp sending for forgot password of instructor
        [otpRegisterByMailInstructor.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.instructorSuccess = false;
        },
        [otpRegisterByMailInstructor.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.registrationOtpSentInstructor = true;
        },
        [otpRegisterByMailInstructor.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.instructorSuccess = false;
        },

        // New reducer for handling OTP submission for registration action
        [submitOtpForRegistrationInstructor.pending]: (state, { payload }) => {
            state.loading = true;
            state.error = null;
            state.instructorSuccess = false;
        },
        [submitOtpForRegistrationInstructor.fulfilled]: (
            state,
            { payload }
        ) => {
            state.loading = false;
            state.userInfo = payload.data;
            state.userToken = payload.token;
            state.alreadyExists = false;
            state.isGoogleRegister = false;
            state.instructorSuccess = payload.status;
            state.isInstructorOnboarded = false;
        },
        [submitOtpForRegistrationInstructor.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.instructorSuccess = false;
        }

        // New reducer for handling forgotPassword action
        // [otpLoginByMail.pending]: (state) => {
        //     state.loading = true;
        //     state.error = null;
        //     state.success = false;
        // },
        // [otpLoginByMail.fulfilled]: (state, { payload }) => {
        //     state.loading = false;
        //     state.error = payload;
        //     state.success = true;
        // },
        // [otpLoginByMail.rejected]: (state, { payload }) => {
        //     state.loading = false;
        //     state.error = payload;
        //     state.success = false;
        // },

        // // New reducer for handling OTP submission action
        // [submitOtpToResetPassword.pending]: (state) => {
        //     state.loading = true;
        //     state.error = null;
        //     state.success = false;
        // },
        // [submitOtpToResetPassword.fulfilled]: (state) => {
        //     state.loading = false;
        //     state.error = null;
        //     state.success = true;
        // },
        // [submitOtpToResetPassword.rejected]: (state, { payload }) => {
        //     state.loading = false;
        //     state.error = payload;
        //     state.success = false;
        // },

        // reducer for handling change password
        // [changePassword.pending]: (state) => {
        //     state.loading = true;
        //     state.error = null;
        //     state.success = false;
        // },
        // [changePassword.fulfilled]: (state, { payload }) => {
        //     state.loading = false;
        //     state.error = payload;
        //     state.success = true;
        // },
        // [changePassword.rejected]: (state, { payload }) => {
        //     state.loading = false;
        //     state.error = payload;
        //     state.success = false;
        // }
        // reducer for get all courses
        // [addReview.pending]: (state) => {
        //     state.loading = true;
        //     state.error = null;
        //     state.success = false;
        // },
        // [addReview.fulfilled]: (state, { payload }) => {
        //     state.loading = false;
        //     state.error = payload;
        //     state.success = true;
        // },
        // [addReview.rejected]: (state, { payload }) => {
        //     state.loading = false;
        //     state.error = payload;
        //     state.success = false;
        // },
    }
});

export const {
    logoutInstructor,
    setGoogleProfile,
    setInstructorUserProfile,
    setInstructorSuccess,
    setUserToken,
    setInstructorSuccessTrue,
    setRegistrationOtpSentInstructor,
    setIsInstructorOnboarded
} = InstructorAuthSlice.actions;
export default InstructorAuthSlice.reducer;

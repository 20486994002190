import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiConfig from '../config/ApiConfig';
import { setActiveStep } from '../containers/Instructor/Auth/Onboarding/activeStepSlice';
import {
    setInstructorSuccess,
    setInstructorSuccessTrue,
    setInstructorUserProfile,
    setIsInstructorOnboarded
} from '../containers/Instructor/Auth/AuthSlices/authSlice';
import { store } from '../store';
import {
    setUserProfile,
    setUserSuccessFalse,
    setUserToken
} from '../containers/Student/Auth/AuthSlices/authSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//register instructor
export const registerInstructor = createAsyncThunk(
    'InstructorAuthSlice/register',
    async (values, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.instructor.register}`,
                values,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (response.data.error) {
                toast.error(response.data.error);
            }
            return response.data;
        } catch (error) {
            console.log('error in instructor register', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

//google api to verify recaptcha token
export const verifyInstructorCaptcha = createAsyncThunk(
    'InstructorAuthSlice/captcha',
    async (token, { rejectWithValue }) => {
        try {
            const res = await axios.post(
                `${ApiConfig.auth.verifyCaptcha}`,
                {
                    recaptchaToken: token
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return res;
        } catch (error) {
            console.log('error in google captcha verify', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

//create teaching domains - instructor onboarding ist step
export const createTeachingDomain = async (data, dispatch, step) => {
    try {
        const response = await axios.post(
            `${ApiConfig.instructor.createTeachingDomain}`,
            {
                userId: data?.userId,
                expertiseDomain: data?.expertiseDomain
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        dispatch(setActiveStep(step)); // dispatch active step of stepper
        store.dispatch(setInstructorUserProfile(response?.data?.data)); // dispatch response to instructor profile
        return response;
    } catch (error) {
        console.log('error in create teaching domain', error);
    }
};

//add instructor preferred domains - instructor onboarding 2nd step
export const addInstructorPreferredDomain = async (data) => {
    try {
        const response = await axios.post(
            `${ApiConfig.instructor.addInstructorPreferredDomain}`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        store.dispatch(setInstructorUserProfile(response?.data?.data)); //dispatch response to instructor profile
        return response;
    } catch (error) {
        console.log('error in add instructor preferred domain', error);
    }
};

//submit instructor profile - instructor onboarding 3rd step
export const submitInstructorProfile = async (
    userId,
    values,
    avatar,
    image,
    navigate,
    setLoading,
    token
) => {
    setLoading(true);
    const formData = new FormData();
    if (values) {
        formData.append('firstName', values.firstname);
        formData.append('lastName', values.lastname);
        formData.append('linkedInUrl', values.linkedin);
        formData.append('facebookUrl', values.facebook);
        formData.append('twitterUrl', values.twitter);
        formData.append('country', values.country);
        formData.append('state', values.state);
    }
    if (avatar) {
        formData.append('avatar', avatar);
    }
    if (image) {
        formData.append('image', image);
    }
    try {
        const response = await axios.post(
            `${ApiConfig.instructor.submitProfile}/${userId}`,
            formData,
            {
                headers: {
                    Accept: '*',
                    'Content-Type': 'multipart/form-data',
                    Authorization: token
                }
            }
        );
        store.dispatch(setInstructorUserProfile(response?.data?.result)); //dispatch response to instructor profile
        store.dispatch(setInstructorSuccessTrue()); //dispatch instructor success state to true
        store.dispatch(setIsInstructorOnboarded()); //dispatch toggle state - if instructor onbaording is completed
        setLoading(false);
        navigate('/instructor');
    } catch (error) {
        console.log('error in submit instructor profile', error);
    }
};

//login instructor manually api
export const loginInstructor = createAsyncThunk(
    'instructorAuthSlice/login',
    async (values, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.instructor.login}`,
                values,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            store.dispatch(setInstructorUserProfile(response?.data?.data)); //dispatch response to instructor profile
            store.dispatch(setUserToken(response?.data)); //dispatch instructor token
            if (response.data.error) {
                toast.error(response.data.error);
                store.dispatch(setUserSuccessFalse()); //dispatch user success false - if error in login
            }
            return response.data;
        } catch (error) {
            console.log('error in login', error);
            toast.error(error.response.data.error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

//google oauth api
export const googleLoginForInstructor = createAsyncThunk(
    'instructorAuthSlice/googleLoginForInstructor',
    async (token, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.instructor.googleOauth}`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            store.dispatch(setInstructorUserProfile(response?.data?.user)); //dispatch response to instructor profile
            return response.data;
        } catch (error) {
            console.log('error in google outh', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

//instructor user switching to student
export const instructorBecomeStudent = async (userId, token) => {
    try {
        const response = await axios.post(
            `${ApiConfig.instructor.instructorBecomeStudent}/${userId}`,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                }
            }
        );
        if (response?.data?.status) {
            store.dispatch(setUserProfile(response?.data?.data)); //dispatch response to user profile
        }
    } catch (error) {
        console.log('error  in instructor become student', error);
    }
};

//student user becoming to instructor
export const studentBecomeInstructor = async (userId, token) => {
    try {
        const response = await axios.post(
            `${ApiConfig.instructor.studentBecomeInstructor}/${userId}`,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                }
            }
        );
    } catch (error) {
        console.log('error in student become instructor', error);
    }
};

//student user switching to instructor
export const switchToInstructorSecondTime = async (userId, token) => {
    try {
        const response = await axios.post(
            `${ApiConfig.instructor.switchToInstructor}/${userId}`,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                }
            }
        );
    } catch (error) {
        console.log('error in switch to instructor', error);
    }
};

// otp login by mail
export const otpRegisterByMailInstructor = createAsyncThunk(
    'instructorAuthSlice/sendRegisterationOtpByMailInstructor',
    async ({ values, navigate, toast }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.instructor.sendRegisterationOtpByMailInstructor}`,
                { email: values?.email },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (response?.data?.status) {
                navigate(`/instructor-register-otp/${values?.email}`, {
                    state: { values: values }
                });
                toast.success('OTP send successfully');
            }
            if (response?.data?.error) {
                toast.error(response?.data?.error);
            }
            return response.data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

// submit otp for registration as instructor
export const submitOtpForRegistrationInstructor = createAsyncThunk(
    'instructorAuthSlice/submitOtpForRegistrationInstructor',
    async ({ otp, email, password, reenterPassword }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.instructor.submitOtpForRegistrationInstructor}`,
                {
                    otp,
                    email,
                    password,
                    reenterPassword
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

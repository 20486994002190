import { createSlice } from '@reduxjs/toolkit';
import {
    googleLogin,
    login,
    register,
    verifyCaptcha,
    otpLoginByMail,
    submitOtpToResetPassword,
    changePassword,
    otpRegisterByMail,
    submitOtpForRegistration
    // addReview
} from '../../../../API/Auth';

const initialState = {
    loading: false,
    userInfo: null,
    userToken: null,
    error: null,
    success: false,
    alreadyExists: null,
    isGoogleRegister: null,
    googleProfile: {},
    userProfile: {},
    alreadyInstructor: null,
    captchaSuccess: false,
    registrationOtpVerified: false,
    registrationOtpSent: false,
    isOnboarded: null,
    changePassOtp: ''
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        //logout reducer
        logout: (state) => {
            state.loading = false;
            state.userInfo = null;
            state.userToken = null;
            state.error = null;
            state.success = false;
            state.captchaSuccess = false;
            state.registrationOtpSent = false;
            state.registrationOtpVerified = false;
            state.alreadyExists = null;
            state.isOnboarded = false;
        },
        toggleForgotPassword: (state) => {
            state.forgotPassword = !state.forgotPassword;
        },
        setGoogleProfile: (state, { payload }) => {
            state.googleProfile = payload;
        },
        setUserProfile: (state, { payload }) => {
            state.userProfile = payload;
            state.userInfo = payload;
        },
        setUserInfo: (state, { payload }) => {
            state.userInfo = payload;
        },
        setUserToken: (state, { payload }) => {
            state.loading = false;
            state.userInfo = payload.data;
            state.userToken = payload.token;
            state.alreadyInstructor = payload?.data?.alreadyInstructor;
            state.success = true;
        },
        setUserSuccess: (state, { payload }) => {
            state.success = true;
        },
        setUserSuccessFalse: (state, { payload }) => {
            state.success = false;
        },
        setUserTokenPayload: (state, { payload }) => {
            state.userToken = payload;
        },
        setRegistrationOtpSent: (state, { payload }) => {
            state.registrationOtpSent = payload;
        },
        setIsOnboarded: (state, { payload }) => {
            state.isOnboarded = true;
        },
        setChangePassOtp: (state, { payload }) => {
            state.changePassOtp = payload;
        }
    },
    extraReducers: {
        //captcha verification reducer
        [verifyCaptcha.pending]: (state) => {
            (state.loading = true),
                (state.error = null),
                (state.captchaSuccess = false);
        },
        [verifyCaptcha.fulfilled]: (state, { payload }) => {
            (state.loading = false),
                (state.captchaSuccess = payload?.data?.data?.success),
                (state.error = null);
            // state.success = true;
        },
        [verifyCaptcha.rejected]: (state, { payload }) => {
            (state.loading = false),
                (state.error = payload),
                (state.captchaSuccess = false);
        },

        //login reducer
        [login.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.success = false;
            state.captchaSuccess = false;
        },
        [login.fulfilled]: (state, { payload }) => {
            // console.log('payload', payload);
            state.loading = false;
            state.userInfo = payload.data;
            state.userToken = payload.token;
            state.alreadyExists = true;
            state.success = payload.status;
            state.captchaSuccess = false;
            state.alreadyInstructor = payload?.data?.alreadyInstructor;
            state.isOnboarded = true;
            // state.error = payload;
        },
        [login.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.success = false;
            state.captchaSuccess = false;
        },

        //google oauth reducers
        [googleLogin.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.success = false;
            state.alreadyExists = null;
        },
        [googleLogin.fulfilled]: (state, { payload }) => {
            // console.log('payload', payload);
            state.loading = false;
            state.userInfo = payload.user;
            state.userToken = payload.token;
            state.alreadyExists = payload.alreadyExist;
            state.isGoogleRegister = true;
            state.alreadyInstructor = payload?.user?.alreadyInstructor;
            state.success = payload.status;
            // setting success state according to existing user or not
            if (payload.alreadyExist) {
                state.isOnboarded = true;
            } else {
                state.isOnboarded = false;
            }
        },
        [googleLogin.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.success = false;
            state.alreadyExists = null;
        },

        // register reducer
        [register.pending]: (state) => {
            (state.loading = true),
                (state.error = null),
                (state.success = false);
        },
        [register.fulfilled]: (state, { payload }) => {
            // console.log('payload', payload);
            state.loading = false;
            state.userInfo = payload.data;
            state.userToken = payload.token;
            state.alreadyExists = false;
            state.isGoogleRegister = false;
            state.alreadyInstructor = payload?.data?.alreadyInstructor;
            state.success = payload.status;
            state.isOnboarded = false;
        },
        [register.rejected]: (state, { payload }) => {
            (state.loading = false),
                (state.error = payload),
                (state.success = false);
        },

        // New reducer for handling forgotPassword action
        [otpLoginByMail.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        [otpLoginByMail.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            // state.success = payload.status;
        },
        [otpLoginByMail.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.success = false;
        },

        // New reducer for handling forgotPassword action
        [otpRegisterByMail.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        [otpRegisterByMail.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.registrationOtpSent = true;
        },
        [otpRegisterByMail.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.success = false;
        },

        // New reducer for handling OTP submission action
        [submitOtpToResetPassword.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        [submitOtpToResetPassword.fulfilled]: (state) => {
            state.loading = false;
            state.error = null;
            // state.success = true;
        },
        [submitOtpToResetPassword.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.success = false;
        },

        // New reducer for handling OTP submission action
        [submitOtpForRegistration.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        [submitOtpForRegistration.fulfilled]: (state, { payload }) => {
            // console.log('payload', payload);
            state.loading = false;
            state.userInfo = payload.data;
            state.userToken = payload.token;
            state.alreadyExists = false;
            state.isGoogleRegister = false;
            state.alreadyInstructor = payload?.data?.alreadyInstructor;
            state.success = payload.status;
            state.isOnboarded = false;
        },
        [submitOtpForRegistration.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.success = false;
        },

        // reducer for handling change password
        [changePassword.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        [changePassword.fulfilled]: (state, { payload }) => {
            // console.log('payload', payload);
            state.loading = false;
            state.error = payload;
            // state.success = payload.status;
        },
        [changePassword.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.success = false;
        }
        // reducer for get all courses
        // [addReview.pending]: (state) => {
        //     state.loading = true;
        //     state.error = null;
        //     state.success = false;
        // },
        // [addReview.fulfilled]: (state, { payload }) => {
        //     state.loading = false;
        //     state.error = payload;
        //     state.success = true;
        // },
        // [addReview.rejected]: (state, { payload }) => {
        //     state.loading = false;
        //     state.error = payload;
        //     state.success = false;
        // },
    }
});

export const { setChangePassOtp } = authSlice.actions;

export const {
    logout,
    setGoogleProfile,
    setUserProfile,
    setUserInfo,
    setUserToken,
    setUserSuccess,
    setUserTokenPayload,
    setUserSuccessFalse,
    setRegistrationOtpSent,
    setIsOnboarded,
    changePassOtp
} = authSlice.actions;
export default authSlice.reducer;

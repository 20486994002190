// const url = "http://192.168.1.8:5000";
const url = 'https://backend.ulearnix.com';

const ApiConfig = {
    auth: {
        register: `${url}/api/v1/users/register`,
        login: `${url}/api/v1/users/login`,
        googleOauth: `${url}/api/v1/users/registerwithGmail`,
        sendOtpByMail: `${url}/api/v1/users/forgetPassword`,
        sendRegisterationOtpByMail: `${url}/api/v1/users/generateOtpToverifyUserEmail`,
        submitOtpToResetPassword: `${url}/api/v1/users/submitOtp`,
        submitOtpForRegistration: `${url}/api/v1/users/submitOtpToVerifyEmail`,
        resetPassword: `${url}/api/v1/users/resetPassword/`,
        showCourse: `${url}/api/v1/course/getAllCourses`,
        createCareerGoal: `${url}/api/v1/onboarding/createcareergoal`,
        getDomainList: `${url}/api/v1/onboarding/domainlist`,
        addIntrestedDomains: `${url}/api/v1/onboarding/addIntrestedDomains`,
        getRelatedTopics: `${url}/api/v1/onboarding/getRelatedTopics`,
        addRelatedTopics: `${url}/api/v1/onboarding/addRelatedTopics`,
        submitUserProfile: `${url}/api/v1/users/submitOnBoardinDetails`,
        getAllMajorCatogeries: `${url}/api/v1/users/getAllMajorCatogeries`,
        verifyCaptcha: `${url}/api/v1/users/verifyCaptcha`
    },
    course: {
        addReview: `${url}/api/v1/course/addReview/`,
        getAllReview: `${url}/api/v1/course/getAllReviewsOfindividualcourse/`,
        deleteReview: `${url}/api/v1/course/deleteReview/`,
        editReview: `${url}/api/v1/course/updateReview/`,
        addToCart: `${url}/api/v1/users/addCourseToCart`,
        addToCartFromWishlist: `${url}/api/v1/users/addCourseToCartFromWishlist`,
        addFreeCourseToMyLearning: `${url}/api/v1/users/enrollUserInfreeCourse`,
        removeFromCart: `${url}/api/v1/users/removeCourseFromCart`,
        getCoursesInCart: `${url}/api/v1/users/getCartOfIndividualUser`,
        getWishlist: `${url}/api/v1/users/getWishListOfIndividualUser`,
        addToWishlist: `${url}/api/v1/users/addCourseToWishlist/`,
        recentlyAddedCourses: `${url}/api/v1/course/getRecentlyAddedCourses`,
        instructorsOnPlatform: `${url}/api/v1/users/getinstructorsOnPlatform`,
        recommendCourses: `${url}/api/v1/course/getRecommendedCourses/`,
        getRealtedCourses: `${url}/api/v1/course/getRelatedCourses`,
        getAllFreeCourses: `${url}/api/v1/course/getAllFreeCourses`,
        getTrendingCategoriesOfMostRatedCourse: `${url}/api/v1/course/getTrendingCategoriesOfMostRatedCourse`,
        getTopCourses: `${url}/api/v1/course/getTopCourses/`,
        coursesOfInstructorInDifferentCategories: `${url}/api/v1/course/coursesOfInstructorInDifferentCategories/`,
        coursesOfInstructorInDifferentCategoriesAfterLogin: `${url}/api/v1/course/coursesOfInstructorInDifferentCategoriesAfterLogin/`,
        getCoursesByCategory: `${url}/api/v1/course/getCoursesByCategory/`,
        getCoursesByCategoryAfterLogin: `${url}/api/v1/course/getCoursesByCategoryAfterLogin/`,
        getFeedbackOnInstructorCategories: `${url}/api/v1/course/getFeedbackOnInstructorCategories/`,
        getIndividualCourse: `${url}/api/v1/course/getCourseById`,
        getIndividualCourseAfterLogin: `${url}/api/v1/course/getCourseByIdAfterLogin`,
        saveModule: `${url}/api/v1/course/saveModuleToResumeCourse`,
        getModuleToResumeCourse: `${url}/api/v1/course/getModuleToResumeCourse`,
        completionRate: `${url}/api/v1/course/getCompletionRateOfCourse`,
        onGoingCourses: `${url}/api/v1/users/getOngoingCoursesOfStudent`,
        onGoingCoursesForCertificate: `${url}/api/v1/users/getOngoingCoursesOfStudentForCertificate`,
        markAsComplete: `${url}/api/v1/course/markedModuleAsComplete`,
        markAsInComplete: `${url}/api/v1/course/markedModuleAsInComplete`,
        restartCourse: `${url}/api/v1/course/reStartCourse`,
        getMarkedModules: `${url}/api/v1/course/getMarkedModules`,
        postAttemptedQuestion: `${url}/api/v1/course/attemptQuestion`,
        postAttemptedFinalAssessment: `${url}/api/v1/course/attemptFinalAssessment`,
        postAssessmentMarks: `${url}/api/v1/course/getMarksObtainedInIndividualUnit`,
        postFinalAssessmentMarks: `${url}/api/v1/course/getScoreOfFinalAssessmetOfIndividual`,
        checkEligibilityToDownloadCertificate: `${url}/api/v1/course/checkEligiblityForCertificateDownload`,
        generateCertificate: `${url}/api/v1/users/generateCertificateForUser`,
        updateCertificate: `${url}/api/v1/users/updateQrOfUserForGeneratedCertificate`,
        verifyCertificate: `${url}/api/v1/users/verifyCertificate`
    },
    createCourse: {
        getAllCourses: `${url}/api/v1/course/getAllCourses`,
        createCourse: `${url}/api/v1/course/create`,
        addCourseDescription: `${url}/api/v1/course/addDescriptionsToCourse`,
        addCourseRequirements: `${url}/api/v1/course/addRequirementsToCourse`,
        createUnit: `${url}/api/v1/course/createUnit`,
        createModule: `${url}/api/v1/course/createModule`,
        addPdfToModule: `${url}/api/v1/course/addPdfContentToModule`,
        addImageToModule: `${url}/api/v1/course/addImageContentToModule`,
        addVideoToModule: `${url}/api/v1/course/addVideoContentToModule`,
        addTextToModule: `${url}/api/v1/course/addTextContentToModule`,
        getCoursePreview: `${url}/api/v1/course/getCourseForPreview`,
        editCreateCourse: `${url}/api/v1/course/editCourseCreate`,
        editCourseDescription: `${url}/api/v1/course/editCourseDesctiption`,
        editCourseRequirements: `${url}/api/v1/course/editCourseRequirements`,
        editUnit: `${url}/api/v1/course/editUnit`,
        editModule: `${url}/api/v1/course/editModule`,
        editVideoContent: `${url}/api/v1/course/editVideoContentInModule`,
        editImageContent: `${url}/api/v1/course/editImageContentInModule`,
        editPdfContent: `${url}/api/v1/course/editPdfContentInModule`,
        editTextContent: `${url}/api/v1/course/editTextContentInModule`,
        getAllMajorCategories: `${url}/api/v1/users/getAllMajorCatogeries`,
        getAllSubCategories: `${url}/api/v1/course/getAllSubCategories`,
        createUnitQuiz: `${url}/api/v1/course/addQuestionsToUnit`,
        createCourseQuiz: `${url}/api/v1/course/addFinalAssessmentToCourse`
    },
    payment: {
        createPaymentSession: `${url}/api/v1/payment/create-checkout-session`,
        paymentHistory: `${url}/api/v1/analytics/getPaymentsHistoryOfIndividualUser`,
        refundPAyment: `${url}/api/v1/course/applyForRefund`,
        getCoursesForRefund: `${url}/api/v1/users/getAvailableCoursesForRefund`
    },
    userProfile: {
        updateUserName: `${url}/api/v1/users/updateProfile/`,
        updateReminders: `${url}/api/v1/users/updateReminderSetting/`,
        updateSocialMediaLinks: `${url}/api/v1/users/updateSocialMediaLinks/`,
        createTestimonial: `${url}/api/v1/testimonial/createTestimonial`,
        getTestimonials: `${url}/api/v1/testimonial/getallTestimonials`,
        sendEmail: `${url}/api/v1/users/contactUsOnEmail`,
        sendEmailBeforeLogin: `${url}/api/v1/users/contactUsOnEmailBeforeLogin`,
        updateProfilePicture: `${url}/api/v1/users/updateUserProfilePicture`,
        addInterest: `${url}/api/v1/users/addIntrest`,
        deleteInterest: `${url}/api/v1/users/deleteIntrest`,
        getEnrolledCourses: `${url}/api/v1/users/getEnrolledCourses`,
        autoLogoutOn: `${url}/api/v1/users/setUsersAutoLogoutOn`,
        getRewardPoints: `${url}/api/v1/users/getRewardPointsOfUser`
    },
    instructor: {
        studentToInstructor: `${url}/api/v1/users/studentBecomeInstructor`,
        register: `${url}/api/v1/users/registerAsInstructor`,
        sendRegisterationOtpByMailInstructor: `${url}/api/v1/users/generateOtpToverifyUserEmail`,
        submitOtpForRegistrationInstructor: `${url}/api/v1/users/submitOtpToVerifyEmailOfInstructor`,
        createTeachingDomain: `${url}/api/v1/onboarding/instructor/createInstructorTeachingDomain`,
        addInstructorPreferredDomain: `${url}/api/v1/onboarding/instructor/addInstructorDomainList`,
        submitProfile: `${url}/api/v1/users/instructor/submitinstructorOnboardingDetails`,
        login: `${url}/api/v1/users/loginAsInstructor`,
        googleOauth: `${url}/api/v1/users/registerwithGmailForInstructor`,
        instructorBecomeStudent: `${url}/api/v1/users/instructorBecomeStudent`,
        studentBecomeInstructor: `${url}/api/v1/users/studentBecomeInstructor`,
        switchToInstructor: `${url}/api/v1/users/switchToInstructor`,
        getDraftCourses: `${url}/api/v1/course/getAllDraftCourses`,
        getPublishedCourses: `${url}/api/v1/course/getAllPublishedCourses`,
        addBankAccount: `${url}/api/v1/account/addBankAccountDetails`,
        uploadCancelCheque: `${url}/api/v1/account/uploadCancelCheque`,
        getBankAccount: `${url}/api/v1/account/getBankAccountDetailsOfUser`,
        deleteBankAccount: `${url}/api/v1/account/deleteBankDetails`,
        getTotalCourseCount: `${url}/api/v1/course/getcountOfCourses`,
        createBio: `${url}/api/v1/users/createUserBio`,
        editBio: `${url}/api/v1/users/editUserBio`,
        getCourseTimeline: `${url}/api/v1/course/getCourseTimelineForInstructor`,
        coursesForApproval: `${url}/api/v1/users/getListOfPendingCoursesForApproval`,
        submitForapproval: `${url}/api/v1/request/createRequest`,
        getInstructorDetails: `${url}/api/v1/users/getInstructorDetails`
    },
    account: {
        activateAccount: `${url}/api/v1/users/activateAccount`,
        deactivateAccount: `${url}/api/v1/users/deactivateAccount`
    },
    reminder: {
        scheduleReminder: `${url}/api/v1/users/scheduleReminder`,
        getReminder: `${url}/api/v1/users/getRemindersofCurrentUser`,
        editReminder: `${url}/api/v1/users/editReminder`,
        deleteReminder: `${url}/api/v1/users/deleteReminder`
    },
    analytics: {
        getInstructorRating: `${url}/api/v1/analytics/instructorRating`,
        overAllRevenueOfInstructor: `${url}/api/v1/analytics/overAllRevenueOfInstructor?filter`,
        learnersOfInstructor: `${url}/api/v1/analytics/getLearnersOfInstrucutor?filter`,
        courseRevenueOfInstructor: `${url}/api/v1/analytics/revenueOfIndividualCourse?filter`,
        totalRevenue: `${url}/api/v1/analytics/getTotalRevenueDifference?filter`,
        latestTransactions: `${url}/api/v1/analytics/getLatestTransactionsOfInstrucutorsCourses?filter`,
        learnerCountOfInstructor: `${url}/api/v1/analytics/getLearnersAnalyticsOfInstructor`
    },
    search: {
        search: `${url}/api/v1/course/searchCourse/?key`
    },
    notifications: {
        getInstructorNotification: `${url}/api/v1/notification/getAllNotificationsForUser`,
        getLearnerNotification: `${url}/api/v1/notification/getAllNotificationsForLearner`
    },
    coupons: {
        getAllCoupons: `${url}/api/v1/coupan/getAllCoupans`,
        getCouponByCode: `${url}/api/v1/coupan/getCoupanByCode`,
        getDiscounts: `${url}/api/v1/discounts/getAllNonExpiredDiscounts`
    }
};

export default ApiConfig;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isInstructorPlatform: false
};

// ------------------ on instructor slice component ----------------------- //
const onInstructorSlice = createSlice({
    name: 'onInstructorSlice',
    initialState,
    reducers: {
        setIsInstructorPlatform: (state, { payload }) => {
            state.isInstructorPlatform = payload;
        }
    }
});

export const { setIsInstructorPlatform } = onInstructorSlice.actions;
export default onInstructorSlice.reducer;

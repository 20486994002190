import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchValue: ''
};

// ------------------ search slice component ----------------------- //
const searchSlice = createSlice({
    name: 'searchSlice',
    initialState,
    reducers: {
        setSearch: (state, { payload }) => {
            // console.log('payload active', payload);
            state.searchValue = payload;
        }
    }
});

export const { setSearch } = searchSlice.actions;
export default searchSlice.reducer;

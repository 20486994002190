import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isBankAccount: null,
    isBankList: null,
    isBankAdded: null,
    selectedBank: null,
    isCheckUploaded: null,
    bankDetails: null
};

const revenueSlice = createSlice({
    name: 'revenueSlice',
    initialState,
    reducers: {
        setIsBankAccount: (state, { payload }) => {
            //is bank account screen active state
            state.isBankAccount = payload;
        },
        setIsBankList: (state, { payload }) => {
            //is bank list screen active
            state.isBankList = payload;
        },
        setIsBankAdded: (state, { payload }) => {
            //is adding bank account screen active state
            state.isBankAdded = payload;
        },
        setSelectedBank: (state, { payload }) => {
            //set selected bank state
            state.selectedBank = payload;
        },
        setIsChequeUploaded: (state, { payload }) => {
            //is cheque uploaded state
            state.isCheckUploaded = payload;
        },
        setBankDetails: (state, { payload }) => {
            //is bank details state
            state.bankDetails = payload;
        }
    }
});

export const {
    setIsBankAccount,
    setIsBankList,
    setIsBankAdded,
    setSelectedBank,
    setIsChequeUploaded,
    setBankDetails
} = revenueSlice.actions;
export default revenueSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    courseId: '',
    unit: {},
    module: {},
    pdf: {},
    image: {},
    text: {},
    video: {}
};

const currentCourse = createSlice({
    name: 'currentCourse',
    initialState,
    reducers: {
        getCurrentCourse: (state, { payload }) => {
            //current course state
            state.data = payload;
            state.courseId = payload?._id;
        },
        getCurrentUnit: (state, { payload }) => {
            //current unit state
            state.unit = payload;
        },
        getCurrentModule: (state, { payload }) => {
            //current module state
            state.module = payload;
        },
        getCurrentModulePdf: (state, { payload }) => {
            //current module pdf
            state.pdf = payload;
        },
        getCurrentModuleVideo: (state, { payload }) => {
            //current module video
            state.video = payload;
        },
        getCurrentModuleImage: (state, { payload }) => {
            //current module image
            state.image = payload;
        },
        getCurrentModuleText: (state, { payload }) => {
            //current module text
            state.text = payload;
        }
    }
});

export const {
    getCurrentCourse,
    getCurrentUnit,
    getCurrentModule,
    getCurrentModuleImage,
    getCurrentModulePdf,
    getCurrentModuleVideo,
    getCurrentModuleText
} = currentCourse.actions;
export default currentCourse.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sortOption: 'default',
    sortAllData: [],
    sortOngoingData: [],
    sortCompletedData: []
};

// ------------------ sortSlice component ----------------------- //
const sortSlice = createSlice({
    name: 'sortSlice',
    initialState,
    reducers: {
        setSortOption: (state, { payload }) => {
            state.sortOption = payload;
        },
        setAllSortData: (state, { payload }) => {
            state.sortAllData = payload;
        },
        setOngoingSortData: (state, { payload }) => {
            state.sortOngoingData = payload;
        },
        setCompletedSortData: (state, { payload }) => {
            state.sortCompletedData = payload;
        }
    }
});

export const {
    setSortOption,
    setAllSortData,
    setOngoingSortData,
    setCompletedSortData
} = sortSlice.actions;
export default sortSlice.reducer;

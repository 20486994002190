import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showNav: false
};

// ------------------ category slice component ----------------------- //
const categorySlice = createSlice({
    name: 'categorySlice',
    initialState,
    reducers: {
        setShowNav: (state, { payload }) => {
            state.showNav = payload;
        }
    }
});

export const { setShowNav } = categorySlice.actions;
export default categorySlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    navCategory: null
};

// ------------------ categoru navy slice component ----------------------- //
const categoryNavSlice = createSlice({
    name: 'categoryNavSlice',
    initialState,
    reducers: {
        setNavCategory: (state, { payload }) => {
            //   console.log("payload active", payload);
            state.navCategory = payload;
        }
    }
});

export const { setNavCategory } = categoryNavSlice.actions;
export default categoryNavSlice.reducer;

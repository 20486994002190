import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeIndex: 0
};

// ------------------ activeIndexSlice component ----------------------- //
const activeIndexSlice = createSlice({
    name: 'activeStepSlice',
    initialState,
    reducers: {
        setActiveIndex: (state, { payload }) => {
            // console.log('payload active', payload);
            state.activeIndex = payload;
        }
    }
});

export const { setActiveIndex } = activeIndexSlice.actions;
export default activeIndexSlice.reducer;

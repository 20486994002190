import { createSlice } from '@reduxjs/toolkit';

// ------------------ assessment/unit slice component ----------------------- //
const initialState = {
    selectedUnit: {
        unit: {},
        index: null
    },
    selectedModule: null,
    selectedContent: {
        title: '',
        description: '',
        media: '',
        type: ''
    },
    isAssessmentStarted: false,
    isAssessmentComplete: false,
    isAssessmentOngoing: false,
    isFinalAssessmentStarted: false,
    isFinalAssessmentComplete: false,
    isFinalAssessmentOngoing: false
};

const selectedContentSlice = createSlice({
    name: 'selectedContentSlice',
    initialState,
    reducers: {
        setSelectedUnit: (state, { payload }) => {
            state.selectedUnit = payload;
        },
        setSelectedModule: (state, { payload }) => {
            state.selectedModule = payload;
        },
        setSelectedContent: (state, { payload }) => {
            state.selectedContent = payload;
        },
        setIsAssessmentStarted: (state, { payload }) => {
            state.isAssessmentStarted = payload;
        },
        setIsAssessmentComplete: (state, { payload }) => {
            state.isAssessmentComplete = payload;
        },
        setIsAssessmentOngoing: (state, { payload }) => {
            state.isAssessmentOngoing = payload;
        },
        setIsFinalAssessmentStarted: (state, { payload }) => {
            state.isFinalAssessmentStarted = payload;
        },
        setIsFinalAssessmentComplete: (state, { payload }) => {
            state.isFinalAssessmentComplete = payload;
        },
        setIsFinalAssessmentOngoing: (state, { payload }) => {
            state.isFinalAssessmentOngoing = payload;
        }
    }
});

export const {
    setSelectedContent,
    setSelectedModule,
    setSelectedUnit,
    setIsAssessmentStarted,
    setIsAssessmentComplete,
    setIsAssessmentOngoing,
    setIsFinalAssessmentStarted,
    setIsFinalAssessmentComplete,
    setIsFinalAssessmentOngoing
} = selectedContentSlice.actions;
export default selectedContentSlice.reducer;

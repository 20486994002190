import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isActiveIndex: 0
};

const activeStepSliceInstructor = createSlice({
    name: 'activeStepSliceInstructor',
    initialState,
    reducers: {
        setActiveStep: (state, { payload }) => {
            state.isActiveIndex = payload;
        }
    }
});

export const { setActiveStep } = activeStepSliceInstructor.actions;
export default activeStepSliceInstructor.reducer;

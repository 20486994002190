import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiConfig from '../config/ApiConfig';
import axios, { Axios } from 'axios';
import { useNavigate } from 'react-router';
import { store } from '../store/index';
import {
    setIsOnboarded,
    setUserProfile,
    setUserSuccess
} from '../containers/Student/Auth/AuthSlices/authSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//register user manually api
export const register = createAsyncThunk(
    'auth/register',
    async (values, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.auth.register}`,
                values,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.error) {
                toast.error(response.data.error);
            }
            return response.data;
        } catch (error) {
            console.log('error', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

//verify recaptcha token api
export const verifyCaptcha = createAsyncThunk(
    'auth/captcha',
    async (token, { rejectWithValue }) => {
        try {
            const res = await axios.post(
                `${ApiConfig.auth.verifyCaptcha}`,
                {
                    recaptchaToken: token
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return res;
        } catch (error) {
            console.log('error in captcha verify', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

//login user manually api
export const login = createAsyncThunk(
    'auth/login',
    async (values, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.auth.login}`,
                values,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            store.dispatch(setUserProfile(response?.data?.data));
            if (response.data.error) {
                toast.error(response.data.error);
            }
            return response.data;
        } catch (error) {
            console.log('error in login', error);
            toast.error(error.response.data.error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                toast.error(error.message); // Display a general error message if server response doesn't contain a specific error message
                return rejectWithValue(error.message);
            }
        }
    }
);

//google oauth api
export const googleLogin = createAsyncThunk(
    'auth/googleOauth',
    async (token, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.auth.googleOauth}`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            store.dispatch(setUserProfile(response?.data?.user));
            return response.data;
        } catch (error) {
            console.log('error in google outh', error);
            toast.error('Google Authentication failed! Please try again.');
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
// otp login by mail
export const otpLoginByMail = createAsyncThunk(
    'auth/sendOtpByMail',
    async ({ values, navigate, toast }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.auth.sendOtpByMail}`,
                { email: values },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (response?.data?.status) {
                navigate(`/otp/${values}`);
            } else {
                toast.error(response?.data?.error);
            }
            return response.data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return toast.error(error.response.data.message);
            } else {
                return toast.error(error.response.data.error);
            }
        }
    }
);

// otp login by mail
export const otpRegisterByMail = createAsyncThunk(
    'auth/sendRegisterationOtpByMail',
    async ({ values, navigate, toast }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.auth.sendRegisterationOtpByMail}`,
                { email: values?.email },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (response?.data?.status) {
                navigate(`/register-otp/${values?.email}`, {
                    state: { values: values }
                });
                toast.success('OTP send successfully');
            } else {
                toast.error(response.data.error);
            }
            return response.data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return toast.error(error.response.data.message);
            } else {
                return toast.error(error.response.data.error);
            }
        }
    }
);

// submit otp
export const submitOtpToResetPassword = createAsyncThunk(
    'auth/submitOtp',
    async (values, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.auth.submitOtpToResetPassword}`,
                {
                    otp: values
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

// submit otp
export const submitOtpForRegistration = createAsyncThunk(
    'auth/submitOtpForRegistration',
    async ({ otp, email, password, reenterPassword }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.auth.submitOtpForRegistration}`,
                {
                    otp,
                    email,
                    password,
                    reenterPassword
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

// changePassword api
export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async (
        { newPassword, confirmNewPassword, userId, otp },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post(
                `${ApiConfig.auth.resetPassword}${userId}`,
                {
                    newPassword,
                    confirmNewPassword,
                    userId,
                    otp
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log('error in change password', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

// Get courses API
export const getCourses = createAsyncThunk(
    'auth/showCourse',
    async (token, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${ApiConfig.auth.showCourse}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                }
            });

            return response.data;
        } catch (error) {
            console.log('error in get courses', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
// creates career goal - student onboarding ist step
export const createCareerGoal = createAsyncThunk(
    'auth/createCareerGoal',
    async (data, { rejectWithValue }) => {
        const data1 = {
            userId: data?.userId,
            careerGoal: data?.careerGoal
        };
        try {
            const response = await axios.post(
                `${ApiConfig.auth.createCareerGoal}`,
                data1,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: data?.token
                    }
                }
            );

            return response.data;
        } catch (error) {
            console.log('error in createCareerGoal', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

// Get domain list API
export const getDomainList = createAsyncThunk(
    'auth/getDomainList',
    async (token, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${ApiConfig.auth.getDomainList}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: token
                    }
                }
            );

            return response.data;
        } catch (error) {
            console.log('error in get domain list', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

// Add interested domains API - student onboarding 2nd step
export const addInterestedDomains = createAsyncThunk(
    'auth/addInterestedDomains',
    async (data, { rejectWithValue }) => {
        const data1 = {
            userId: data?.userId,
            selectedDomains: data?.selectedDomains
        };
        try {
            const response = await axios.post(
                `${ApiConfig.auth.addIntrestedDomains}`,
                data1,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: data?.token
                    }
                }
            );

            return response.data;
        } catch (error) {
            console.log('error in addInterestedDomains', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

// Get related topics API
export const getRelatedTopics = createAsyncThunk(
    'auth/getRelatedTopics',
    async ({ userId, token }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiConfig.auth.getRelatedTopics}`,
                {
                    userId
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: token
                    }
                }
            );

            return response.data;
        } catch (error) {
            console.log('error in getRelatedTopics', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

//  adding related topics - student onboarding 3rd step
export const addRelatedTopics = createAsyncThunk(
    'auth/addRelatedTopics',
    async (data, { rejectWithValue }) => {
        const data1 = {
            userId: data?.userId,
            selectedTopics: data?.selectedTopics
        };
        try {
            const response = await axios.post(
                `${ApiConfig.auth.addRelatedTopics}`,
                data1,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: data?.token
                    }
                }
            );

            return response.data;
        } catch (error) {
            console.log('error in addRelatedTopics', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

//submit user profile - student onboarding last step
export const submitUserProfile = async (
    userId,
    values,
    avatar,
    image,
    navigate,
    setLoading,
    token
) => {
    setLoading(true);
    const formData = new FormData();
    if (values) {
        formData.append('firstName', values.firstname);
        formData.append('lastName', values.lastname);
        formData.append('linkedInUrl', values.linkedin);
        formData.append('facebookUrl', values.facebook);
        formData.append('twitterUrl', values.twitter);
        formData.append('country', values.country);
        formData.append('state', values.state);
    }
    if (avatar) {
        formData.append('avatar', avatar);
    }
    if (image) {
        formData.append('image', image);
    }
    try {
        const response = await axios.post(
            `${ApiConfig.auth.submitUserProfile}/${userId}`,
            formData,
            {
                headers: {
                    Accept: '*',
                    'Content-Type': 'multipart/form-data',
                    Authorization: token
                }
            }
        );
        store.dispatch(setUserProfile(response?.data?.result));
        store.dispatch(setUserSuccess());
        store.dispatch(setIsOnboarded());
        setLoading(false);
        navigate('/dashboard');
    } catch (error) {
        console.log('error in submit user profile', error);
    }
};

// get getAllMajorCatogeries
export const getAllMajorCategories = createAsyncThunk(
    'auth/getAllMajorCategories',
    async (token, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${ApiConfig.auth.getAllMajorCatogeries}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${token}`
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error in getAllMajorCategories', error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

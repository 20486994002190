import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isActiveIndex: 0
};

// ------------------ active tab slice component ----------------------- //
const activeTabSlice = createSlice({
    name: 'activeTabSlice',
    initialState,
    reducers: {
        setActiveTab: (state, { payload }) => {
            state.isActiveIndex = payload;
        }
    }
});

export const { setActiveTab } = activeTabSlice.actions;
export default activeTabSlice.reducer;

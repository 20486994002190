import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isActiveIndex: 0,
    isDomainList: []
};

// ------------------ actibve step slice component ----------------------- //
const activeStepSlice = createSlice({
    name: 'activeStepSlice',
    initialState,
    reducers: {
        setActiveStep: (state, { payload }) => {
            // console.log('payload active', payload);
            state.isActiveIndex = payload;
        },
        setIsDomainList: (state, { payload }) => {
            state.isDomainList = payload;
        }
    }
});

export const { setActiveStep, setIsDomainList } = activeStepSlice.actions;
export default activeStepSlice.reducer;

import authSlice from '../containers/Student/Auth/AuthSlices/authSlice';
import activeStepSlice from '../containers/Student/Pages/Onboarding/activeStepSlice';
import categorySlice from '../common/components/Student/Navbar/categorySlice';
import selectedContentSlice from '../containers/Student/Pages/Courseunit/selectedContentSlice';
import courseDetailsActiveTabSlice from '../containers/Student/Pages/CourseDetails/tabSlice';
import onInstructorSlice from '../common/components/Student/Navbar/onInstructor';
import activeTabSlice from '../common/components/Instructor/Sidebar/activeTabSlice';
import currentScreen from '../containers/Instructor/Pages/CreateCourse/Slices/currentScreen';
import unitSlice from '../containers/Instructor/Pages/CreateCourse/Slices/unitSlice';
import currentCourse from '../containers/Instructor/Pages/CreateCourse/Slices/currentCourse';
import InstructorAuthSlice from '../containers/Instructor/Auth/AuthSlices/authSlice';
import activeIndexSlice from '../containers/Student/Pages/Settings/activeIndexSlice';
import selectedOverviewSlice from '../containers/Instructor/Pages/CreateCourse/Slices/selectedContentSlice';
import activeStepSliceInstructor from '../containers/Instructor/Auth/Onboarding/activeStepSlice';
import revenueSlice from '../containers/Instructor/Pages/Revenue/revenueSlice';
import searchSlice from '../containers/Student/Pages/Search/searchSlice';
import categoryNavSlice from '../containers/Student/Pages/Dashboard/categoryNavSlice';
import sortSlice from '../containers/Student/Pages/MyLearning/sortSlice';
import receiptSlice from '../containers/Student/Pages/Settings/Screens/receiptSlice';

const rootReducer = {
    auth: authSlice,
    instructorAuth: InstructorAuthSlice,
    activeStepSlice: activeStepSlice,
    activeStepSliceInstructor: activeStepSliceInstructor,
    categorySlice: categorySlice,
    selectedContentSlice: selectedContentSlice,
    courseDetailsActiveTabSlice: courseDetailsActiveTabSlice,
    onInstructorSlice: onInstructorSlice,
    activeTabSlice: activeTabSlice,
    currentScreen,
    unitSlice,
    currentCourse,
    activeIndexSlice: activeIndexSlice,
    selectedOverviewSlice: selectedOverviewSlice,
    revenueSlice: revenueSlice,
    searchSlice: searchSlice,
    categoryNavSlice: categoryNavSlice,
    sortSlice: sortSlice,
    receiptSlice: receiptSlice
};

export default rootReducer;

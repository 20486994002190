import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: null
};

// ------------------ receipt slice component ----------------------- //
const receiptSlice = createSlice({
    name: 'receiptSlice',
    initialState,
    reducers: {
        setReceiptData: (state, { payload }) => {
            // console.log('payload active', payload);
            state.data = payload;
        }
    }
});

export const { setReceiptData } = receiptSlice.actions;
export default receiptSlice.reducer;

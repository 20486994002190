import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    id: '',
    isEditable: false,
    isLoading: false,
    resetForm: false,
    showAddButtons: false
    // prevScreen: {
    //     courseIntro: 'courseCover',
    //     courseDetails: 'courseIntro',
    //     units: "courseDetails"
    // }
};
const unitSlice = createSlice({
    name: 'unitSlice',
    initialState,
    reducers: {
        setUnitScreenName: (state, { payload }) => {
            //current unit screen name
            state.name = payload;
        },
        setUnitScreenId: (state, { payload }) => {
            state.id = payload;
        },
        setUnitScreenState: (state, { payload }) => {
            //current unit is editable or not
            state.isEditable = payload;
        },
        setUnitScreenLoading: (state, { payload }) => {
            //loading state
            state.isLoading = payload;
        },
        resetUnitScreenForm: (state, { payload }) => {
            //reset form toggle
            state.resetForm = payload;
        }
    }
});

export const {
    setUnitScreenName,
    setUnitScreenId,
    setUnitScreenState,
    setUnitScreenLoading,
    resetUnitScreenForm
} = unitSlice.actions;

export default unitSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: 'courseCover',
    id: '',
    editable: false,
    isLoading: false,
    resetForm: false,
    showAddButtons: false,
    prevScreen: {
        courseIntro: 'courseCover',
        courseDetails: 'courseIntro',
        units: 'courseDetails',
        preview: 'units'
    }
};
const currentScreen = createSlice({
    name: 'currentScreen',
    initialState,
    reducers: {
        setCurrentScreenName: (state, { payload }) => {
            //current course screen name
            state.name = payload;
        },
        setCurrentScreenId: (state, { payload }) => {
            state.id = payload;
        },
        setCurrentScreenState: (state, { payload }) => {
            //current course is editable or not
            state.editable = payload;
        },
        setCurrentScreenLoading: (state, { payload }) => {
            //loading state
            state.isLoading = payload;
        },
        resetCurrentScreenForm: (state, { payload }) => {
            //reset form toggle
            state.resetForm = payload;
        }
    }
});

export const {
    setCurrentScreenName,
    setCurrentScreenId,
    setCurrentScreenState,
    setCurrentScreenLoading,
    resetCurrentScreenForm
} = currentScreen.actions;

export default currentScreen.reducer;

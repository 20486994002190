import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeTab: 0
};

// ------------------ courseDetailsActiveTabSlice component ----------------------- //
const courseDetailsActiveTabSlice = createSlice({
    name: 'courseDetailsActiveTabSlice',
    initialState,
    reducers: {
        setActiveTab: (state, { payload }) => {
            state.activeTab = payload;
        }
    }
});

export const { setActiveTab } = courseDetailsActiveTabSlice.actions;
export default courseDetailsActiveTabSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedUnit: {
        unit: {},
        index: null
    },
    selectedModule: null,
    selectedContent: {
        title: '',
        description: '',
        media: '',
        type: ''
    }
};

const selectedOverviewSlice = createSlice({
    name: 'selectedOverviewSlice',
    initialState,
    reducers: {
        setSelectedUnit: (state, { payload }) => {
            //selected current unit state
            state.selectedUnit = payload;
        },
        setSelectedModule: (state, { payload }) => {
            //selected module state
            state.selectedModule = payload;
        },
        setSelectedContent: (state, { payload }) => {
            //selected content state
            state.selectedContent = payload;
        }
    }
});

export const { setSelectedContent, setSelectedModule, setSelectedUnit } =
    selectedOverviewSlice.actions;
export default selectedOverviewSlice.reducer;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store, history } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { CircularProgress, Stack } from '@mui/material';
import { CurrentChatProvider } from './containers/ChatBox/ChatContext';

const App = React.lazy(() => import('./App'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CurrentChatProvider>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={history}>
                    <ErrorBoundary>
                        <Suspense
                            fallback={
                                <Stack
                                    sx={{
                                        width: '100%',
                                        height: '100vh',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#3C1698'
                                    }}
                                >
                                    <CircularProgress
                                        style={{ color: '#ffffff' }}
                                        size="10rem"
                                    />
                                </Stack>
                            }
                        >
                            <App />
                        </Suspense>
                    </ErrorBoundary>
                </Router>
            </PersistGate>
        </Provider>
    </CurrentChatProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
